import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";

export const newsAdapter = createEntityAdapter({
    selectId: (news) => news.id,
    sortComparer: (a, b) => {
        const aDate = new Date(a.date).getTime();
        const bDate = new Date(b.date).getTime();
        return bDate - aDate;
    },
});


const news = createSlice({
    name: "news",
    initialState: newsAdapter.getInitialState([]),
    reducers: {
        setAllNews: newsAdapter.setAll,
        setOneNews: newsAdapter.setOne,
        setMany: newsAdapter.setMany
    }
});


const newsGallery = createSlice({
    name: "gallery",
    initialState: newsAdapter.getInitialState([]),
    reducers: {
        setAllNews: newsAdapter.setAll,
        setOneNews: newsAdapter.setOne,
        setMany: newsAdapter.setMany
    }
});

export const {setAllNews, setOneNews, setMany} = news.actions;

export default news.reducer;