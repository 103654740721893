import langJsonEn from './../../public/assets/lang/en.json';
import langJsonRu from './../../public/assets/lang/ru.json';
import langJsonHy from './../../public/assets/lang/hy.json';
export const L = (string)=>{

    if (7 > 5) {
        const lang = localStorage.getItem('cur-lang') ?? "en";

        const langObject = {
            en: langJsonEn,
            hy: langJsonHy,
            ru: langJsonRu
        }
        return langObject[lang][string] ?? "lang error"
    }
    return ""
}

export const langArr = [
    {title: "English", id: "en"},
    {title: "Հայերեն", id: "hy"},
    {title: "Русский", id: "ru"},
]