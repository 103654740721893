import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const newsgallery = createSlice({
    name: "newsgallery",
    initialState: initialProfileState,
    reducers: {
        setNewsGallery: (state, {payload}) => {
            return payload;
        }
    }
});

export const {setNewsGallery} = newsgallery.actions;

export default newsgallery.reducer;