import {Link, useParams} from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Counter from "../components/Counter";
import Layouts from "../layouts/Layouts";
import { fiveItemCarousel, testimonialSlider } from "../sliderProps";
import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {conn} from "../helpers/api";
import {setAboutMenuItems} from "../redux/slices/aboutMenu";
import {setPage} from "../redux/slices/pageSlice";
import {getPage} from "../redux/selectors/pageSelector";
import {image_base_url} from "../helpers";
import {setLang} from "../redux/slices/lang";
import Store from "../redux/stores/store";
import {L} from "../helpers/lang/lang";
import store from "../redux/stores/store";
import Index1Hero from "../components/slider/Index1Hero";
import DonutChart from "../components/DonutChart";
import {setAbout} from "../redux/slices/pageAbout";
import {setFooterNews} from "../redux/slices/pageFooterNews";
import {prettyDate} from "../helpers/date";
import bg_for_section from  "../public/assets/images/background/child.jpg";
import bg_cta from "../public/assets/images/background/map.png";
import bg_partners from "../public/assets/images/background/cta.jpg";
import donation_box_img from "../public/assets/images/resource/donation_img.jpg";
import DonationPopUp from "../components/donation_pop_up";
import CardSlider from "../components/CardsSlider/CardSlider";
import CardOrder from "../components/CardOrder/CardOrder";

const IndexContent = () => {
    const params = useParams();
    const dispatch = useDispatch();
    let count = 0;

    const [order, setOrder] = useState(false);
    const [donate, setDonate] = useState(false);

    useEffect(()=>{
        dispatch(setLang(localStorage?.getItem("cur-lang") ?? "en"));
    }, [])

        useEffect(()=>{
            conn("main", {}).then(res=>{
                console.log("res", res);
                dispatch(setAboutMenuItems(res.about_menu));
                dispatch(setFooterNews(res.footer_news));
                dispatch(setPage(res.main));
            })
        }, []);

        const page = useSelector(getPage());

    //     let amountBtnBg = "";
    //
    // function verifyAmountBtnBg() {
    //     if (amountBtnBg == "li_one") {
    //         document.querySelector('.amount_btn_one').setAttribute('id', 'selected_amount_btn');
    //         document.querySelector('.amount_btn_two').removeAttribute('id');
    //         document.querySelector('.amount_btn_tree').removeAttribute('id');
    //         document.querySelector('.amount_btn_for').removeAttribute('id');
    //     } else if (amountBtnBg == "li_two") {
    //         document.querySelector('.amount_btn_one').removeAttribute('id');
    //         document.querySelector('.amount_btn_two').setAttribute('id', 'selected_amount_btn');
    //         document.querySelector('.amount_btn_tree').removeAttribute('id');
    //         document.querySelector('.amount_btn_for').removeAttribute('id');
    //     } else if (amountBtnBg == "li_tree") {
    //         document.querySelector('.amount_btn_one').removeAttribute('id');
    //         document.querySelector('.amount_btn_two').removeAttribute('id');
    //         document.querySelector('.amount_btn_tree').setAttribute('id', 'selected_amount_btn');
    //         document.querySelector('.amount_btn_for').removeAttribute('id');
    //     } else if (amountBtnBg == "li_for") {
    //         document.querySelector('.amount_btn_one').removeAttribute('id');
    //         document.querySelector('.amount_btn_two').removeAttribute('id');
    //         document.querySelector('.amount_btn_tree').removeAttribute('id');
    //         document.querySelector('.amount_btn_for').setAttribute('id', 'selected_amount_btn');
    //     }
    // }


    return (
        <Layouts key={5}>
            {donate? <DonationPopUp onClose={()=>setDonate(false)}/>: null}
            <section className="banner-section-one">
                {/*<Index1Hero />*/}
                <CardSlider/>
            </section>
            {/*End Banner Section One */}
            {/* section about */}
            <section className="about">
                <div className="top-title text-center">
                    <div
                        className="wow slideInUp animated"
                        data-wow-delay="0.1s"
                        data-wow-duration="1500ms"
                    >
                        <div className="count-outer count-box">
                            {/* <span className="count-text" data-speed={3000} data-stop={25360}>
                0
              </span> */}
                            {/*<Counter end={25360} />*/}
                            {/*<span className="plus">+</span>*/}
                            <span>{L("trusted_partners")}</span>
                        </div>
                    </div>
                </div>
                <div className="logo">
                    <img src="assets/images/resource/icon-3.png" alt="" />
                </div>
                <div className="theme_container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="block-one">
                                <div className="image">
                                    <img src={image_base_url + page?.home_about?.image} alt="" />
                                </div>
                                <div className="image-two">
                                    <img src="assets/images/resource/icon.png" alt="" />
                                </div>
                                <div className="image-three">
                                    <img src="assets/images/resource/icon-2.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="block-two">
                                <div className="sub-title mb-20">
                  <span className="title-sep-left">
                    <img src="assets/images/resource/icon-4.png" alt="" />
                  </span>
                                    {/* {L("about_himnadram")} */}
                                </div>
                                <h2 className="sec-title mb-20">
                                    {page?.home_about?.title}
                                </h2>
                                <div className="text">
                                    {page?.home_about?.text?.replaceAll("&quot;", '"')}
                                </div>
                                <div className="icon-list">
                                    <ul>
                                        {page?.home_about?.content?.map(title=>{
                                            return <li key={title.id}>
                                                <i className="fas fa-check" />
                                                {title}
                                            </li>
                                        })}
                                    </ul>
                                </div>
                                <div className="inner-box">
                                    <div className="link-btn">
                                        <Link>
                                            <a className="primary_btn" onClick={()=>setDonate(true)}>
                                                {L("make_donation")}
                                                <i className="far fa-heart" />
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* donar section */}
            {order? <CardOrder onClose={()=>setOrder(false)} onSend={()=>setDonate(true)}/>: null}
            <section className="donar">
                <div className="theme_container">
                    <div className="logo">
                        <img src="assets/images/resource/icon-5.png" alt="" />
                    </div>
                    <div className="row no-gutters">
                        <div className="col-lg-6">
                            <div className="donar-block">
                                <div className="sub-title mb-20 style-two">
                  <span className="title-sep-left">
                    <img src="assets/images/resource/icon-6.png" alt="" />
                  </span>
                                    {/* {L("make_donate")} */}
                                </div>
                                <h2 className="sec-title style-two mb-30" style={{fontSize: 21, fontWeight: 100, letterSpacing: 0.7 }}>{L("become_a_donar")}</h2>
                                {/*<div className="bottom">*/}
                                {/*        <div className="about-section-two-input-box">*/}
                                {/*            <input*/}
                                {/*                type="email"*/}
                                {/*                className={'donation_email'}*/}
                                {/*                placeholder={L("email_address")}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*</div>*/}
                                <div className="project-form style-two">
                                        {/*<ul className="donation-amount">*/}
                                        {/*    <li className={'amount_btn_one'} key={1} value={1000} onClick={(e)=>{*/}
                                        {/*        document.querySelector('.money_input').value = e.target.value;*/}
                                        {/*        amountBtnBg = "li_one";*/}
                                        {/*        verifyAmountBtnBg();*/}
                                        {/*    }}>$1000</li>*/}
                                        {/*    <li className={'amount_btn_two'} key={2} value={500} onClick={(e)=>{*/}
                                        {/*        document.querySelector('.money_input').value = e.target.value;*/}
                                        {/*        amountBtnBg = "li_two";*/}
                                        {/*        verifyAmountBtnBg();*/}
                                        {/*    }}>$500</li>*/}
                                        {/*    <li className={'amount_btn_tree'} key={3} value={100} onClick={(e)=>{*/}
                                        {/*        document.querySelector('.money_input').value = e.target.value;*/}
                                        {/*        amountBtnBg = "li_tree";*/}
                                        {/*        verifyAmountBtnBg();*/}
                                        {/*    }}>$100</li>*/}
                                        {/*    <li className={'amount_btn_for'} key={4} value={50} onClick={(e)=>{*/}
                                        {/*        document.querySelector('.money_input').value = e.target.value;*/}
                                        {/*        amountBtnBg = "li_for";*/}
                                        {/*        verifyAmountBtnBg();*/}
                                        {/*    }}>$50</li>*/}
                                        {/*</ul>*/}
                                    <div className="from-button center_open_button">
                                        <span onClick={()=>setDonate(true)}>
                                            <a className="primary_btn-one">
                                                {L("make_donation")}
                                                <i className="far fa-heart" />
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="image donation_img">
                                <img src={donation_box_img} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* features section */}
            <section className="features pt-0">
                stex!!!
                <div
                    className="bg"
                    style={{ backgroundImage: "url(" + bg_for_section + ")" }}
                />
                <div className="theme_container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="features-block">
                                <div className="icon-box">
                                </div>
                                <div className="content">
                                    <h4>{L("project_done")}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="features-block">
                                <div className="icon-box style-two">
                                </div>
                                <div className="content">
                                    <h4>{L("expert_volunteer")}</h4>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="features-block">
                                <div className="icon-box style-three">
                                </div>
                                <div className="content">
                                    <h4>{L("global_partners")}</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="features-block">
                                <div className="icon-box style-four">
                                </div>
                                <div className="content">
                                    <h4>{L("raised_now")}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  Causes Section */}
            <section className="causes">
                <div className="theme_container">
                    <div className="title-box">
                        <div className="sub-title mb-20">
              <span className="title-sep-left">
                <img src="assets/images/resource/icon-4.png" alt="" />
              </span>
                        </div>
                        <h2 className="sec-title mb-56">
                            {L("how_help_foundation")}
                        </h2>
                    </div>
                    <div className="row">
                        {
                            page?.foundation?.map(val => {
                                let percentValue = val.goal / 100;
                                let percentStatus = (val.raised / percentValue).toFixed(0);
                                return (
                                    <div key={val.id} className="col-xl-3 col-lg-4 col-md-6">
                                        <div className="causes-block">
                                            <div className="image">
                                                <img src={image_base_url + val.image} alt="" />
                                                <div className="progress-wrap">
                                                    <div className="progress-box">
                                                        <div className="inner-box">
                                                            <div className="graph-outer">
                                                                <DonutChart value={percentStatus} color={"#f74f22"} />

                                                                <div className="inner-text count-box">
                                                                    <Counter end={percentStatus} />
                                                                    <span className="count-Parsent">%</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="content">
                                                        <h4>{val.percent_title}</h4>
                                                        <div className="text">
                                                            {L("raised")}<span className="crl4"> ֏{val.raised}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="lower-content">
                                                <h4>
                                                    {val.title}
                                                </h4>
                                                <ul>
                                                    <li key={val.id}>
                                                        {L("goal")}<span>֏{val.goal}</span>
                                                    </li>
                                                    {/* <li key={val.id}>
                                                        {L("raise")}<span className="crl2">${val.raise}</span>
                                                    </li>
                                                    <li key={val.id}>
                                                        {L("to_go")}<span className="crl3">${val.to_go}</span>
                                                    </li> */}
                                                </ul>
                                                <Link to={'/donation-details/' + val.id}>
                                                    <a className="primary_btn style-two">
                                                        {L("read_more")}
                                                        <i className="far fa-heart" />
                                                    </a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            {/* cta section */}
            <section className="cta">
                <div
                    className="bg"
                    style={{ backgroundImage: "url(" + bg_cta + ")" }}
                />
                <div className="theme_container">
                    <div className="cta-text text-center mb-40">
                        <span>{L("small")}</span> {L("donations")} <span>{L("make")}</span> {L("bigger")}
                    </div>
                    <div className="inner-box">
                        <div className="link-btn">
                            <Link>
                                <a className="primary_btn-one" onClick={()=>setDonate(true)}>
                                    {L("make_donation")}
                                    <i className="far fa-heart" />
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* gallery section */}
            <section className="gallery">
                <div className="theme_container">
                    <div className="row">
                        {
                            page?.gallery?.map(val => {
                                count++;
                                    if (count == 1) {
                                        return (
                                            <div key={val.id} className="col-lg-8 col-md-8">
                                                <div className="gallery-block">
                                                    <div className="image">
                                                        <img src={image_base_url + val.image} alt="" />
                                                    </div>
                                                    <div className="inner-box">
                                                        <h4>
                                                            <a href={image_base_url + val.image} target={'_blank'}>
                                                                <a>{val.title}</a>
                                                            </a>
                                                        </h4>
                                                        <div className="text">{val.text}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (count == 2) {
                                        return (
                                            <div key={val.id} className="col-lg-4 col-md-4 flex-box-one">
                                                <div className="gallery-block">
                                                    <div className="image">
                                                        <img src={image_base_url + val.image} alt="" />
                                                    </div>
                                                    <div className="inner-box">
                                                        <h4>
                                                            <Link href="/gallery">
                                                                <a>{val.title}</a>
                                                            </Link>
                                                        </h4>
                                                        <div className="text">{val.text}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (count == 3) {
                                        return (
                                            <div key={val.id} className="col-lg-4 col-md-4">
                                                <div className="gallery-block">
                                                    <div className="image">
                                                        <img src={image_base_url + val.image} alt="" />
                                                    </div>
                                                    <div className="inner-box">
                                                        <h4>
                                                            <Link href="/gallery">
                                                                <a>{val.title}</a>
                                                            </Link>
                                                        </h4>
                                                        <div className="text">{val.text}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else if (count == 4) {
                                        return (
                                            <div key={val.id} className="col-lg-8 col-md-8">
                                                <div className="gallery-block">
                                                    <div className="image">
                                                        <img src={image_base_url + val.image} alt="" />
                                                    </div>
                                                    <div className="inner-box">
                                                        <h4>
                                                            <Link href="/gallery">
                                                                <a>{val.title}</a>
                                                            </Link>
                                                        </h4>
                                                        <div className="text">{val.text}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                            })
                        }
                    </div>
                </div>
            </section>
            {/* Partner section */}
            <section className="partner">
                <div
                    className="bg"
                    style={{ backgroundImage: "url(" + bg_partners + ")" }}
                />
                <div className="theme_container">
                    <Swiper
                        {...fiveItemCarousel}
                        className="five-item-carousel owl-theme owl-carousel"
                    >
                        {

                            page?.partners?.map(val => {
                                return (
                                    <>
                                        <SwiperSlide key={val.id} className="image" style={{height: "60px", textAlign: "center"}}>
                                            <img src={image_base_url + val.image} style={{height: "100%"}} alt=""/>
                                        </SwiperSlide>
                                    </>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </section>
            {/* funfact section */}
            <section className="funfact">
                <div className="theme_container">
                    <div className="row">
                        {/*Start Counter Block One*/}
                        <div
                            className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft"
                            data-wow-delay="0ms"
                            data-wow-duration="1500ms"
                        >
                            <div className="counter-block-one">
                                <div className="count-outer count-box">
                                    <div className="icon">
                                        <i className="flaticon-charity" />
                                    </div>
                                    {/* <span
                    className="count-text"
                    data-speed={3000}
                    data-stop={5260}
                  >
                    0
                  </span> */}
                                    <Counter end={page?.home_about?.goal} />
                                    <span className="plus">+</span>
                                    <div className="text">
                                        <p>{L("global_partners")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*End Counter Block One*/}
                        {/*Start Counter Block One*/}
                        <div
                            className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft"
                            data-wow-delay="0ms"
                            data-wow-duration="1500ms"
                        >
                            <div className="counter-block-one">
                                <div className="count-outer count-box">
                                    <div className="icon">
                                        <i className="flaticon-volunteer" />
                                    </div>
                                    {/* <span
                    className="count-text"
                    data-speed={3000}
                    data-stop={7853}
                  >
                    0
                  </span> */}
                                    <Counter end={page?.home_about?.raise} />
                                    <span className="plus">+</span>
                                    <div className="text">
                                        <p>{L("expert_volunteer")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*End Counter Block One*/}
                        {/*Start Counter Block One*/}
                        <div
                            className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft"
                            data-wow-delay="0ms"
                            data-wow-duration="1500ms"
                        >
                            <div className="counter-block-one">
                                <div className="count-outer count-box">
                                    <div className="icon">
                                        <i className="flaticon-debit-card" />
                                    </div>
                                    {/* <span
                    className="count-text"
                    data-speed={3000}
                    data-stop={8564}
                  >
                    0
                  </span> */}

                                    <Counter end={page?.home_about?.to_go} />
                                    <span className="plus">+</span>
                                    <div className="text">
                                        <p>{L("project_done")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*End Counter Block One*/}
                        {/*Start Counter Block One*/}
                        <div
                            className="col-xl-3 col-lg-6 col-md-6 wow fadeInLeft"
                            data-wow-delay="0ms"
                            data-wow-duration="1500ms"
                        >
                            <div className="counter-block-one">
                                <div className="count-outer count-box">
                                    <div className="icon">
                                        <i className="flaticon-solidarity" />
                                    </div>
                                    {/* <span
                    className="count-text"
                    data-speed={3000}
                    data-stop={8962}
                  >
                    0
                  </span> */}

                                    <Counter end={page?.home_about?.order} />
                                    <span className="plus">+</span>
                                    <div className="text">
                                        <p>{L("raised_now")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*End Counter Block One*/}
                    </div>
                </div>
            </section>
            {/* news section */}
            <section className="news">
                <div className="theme_container">
                    <div className="title-box text-center ">
                        <div className="sub-title mb-20">
              <span className="title-sep-left">
                <img src="assets/images/resource/icon-4.png" alt="" />
              </span>
                            {L("article_news")}
                            <span className="title-sep-right">
                <img src="assets/images/resource/icon-4.png" alt="" />
              </span>
                        </div>
                        {/* <h2 className="sec-title mb-56">
                            {L("news_top_title")}
                        </h2> */}
                    </div>
                    <div className="row">
                        {
                            page?.news?.map(val => {
                                return (
                                    <div className="col-lg-4">
                                        <div className="news-block">
                                            <div className="image">
                                                <img src={image_base_url + val.image} alt="" />
                                            </div>
                                            <div className="lower-content">
                                                <div className="date">
                                                    <i className="far fa-calendar-alt" />
                                                    {prettyDate(val.date)}
                                                </div>
                                                <h4>
                                                    <a>
                                                        {val.title}
                                                    </a>
                                                </h4>
                                                <Link to={"/blog/more-blog/" + val.id}>
                                                    <a className="news-btn">
                                                        <span>{L("read_more")}</span>
                                                        <i className="far fa-arrow-right" />
                                                    </a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        </Layouts>
    );
};
export default IndexContent;
