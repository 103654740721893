import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const page = createSlice({
    name: "page",
    initialState: initialProfileState,
    reducers: {
        setPage: (state, {payload}) => {
           return payload;
        }
    }
});

export const {setPage} = page.actions;

export default page.reducer;