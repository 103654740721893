import {Link} from "react-router-dom";
import {Fragment, useEffect} from "react";
import {L} from "../helpers/lang/lang";
import {image_base_url} from "../helpers";
import {conn} from "../helpers/api";
import {setFooterNews} from "../redux/slices/pageFooterNews";
import {useDispatch, useSelector} from "react-redux";
import {getFooter} from "../redux/selectors/pageFooterSelector";
import {prettyDate} from "../helpers/date";
import {setLang} from "../redux/slices/lang";

const Footer = ({ extraClass }) => {

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setLang(localStorage?.getItem("cur-lang") ?? "en"));
  }, [])

  const page = useSelector(getFooter());

  return (
    <Fragment>
      <footer className={extraClass ? extraClass : "main-footer"}>
        <div className="main-footer-top">
          <div className="theme_container">
            <div className="widgets-section">
              <div className="row clearfix">
                {/*Start Footer Widget Item*/}
                <div
                  className="col-lg-3 col-md-6 wow animated fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <div className="footer-widget-item">
                    <div className="title">
                      <h2>{L("about_us")}</h2>
                    </div>
                    <div className="our-info">
                      <div className="text">
                        <p>
                          {L("footer_text")}
                        </p>
                      </div>
                    </div>
                    <div className="icon-list">
                      <ul>
                      {/*  <li>
                          <div className="icon">
                            <i className="far fa-map-marker-alt" />
                          </div>
                          <div className="text">
                            {L("address_lang")}
                          </div>
                        </li>*/}
                        <li>
                          <div className="icon">
                            <i className="far fa-envelope" />
                          </div>
                          <div className="text">info@powerofdiaspora.com</div>
                        </li>
                        <li>
                        {/*  <div className="icon">
                            <i className="far fa-phone" />
                          </div>*/}
                         {/* <div className="text">
                            +374 43 12 00 55
                          </div>*/}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/*End Footer Widget Item*/}
                {/* Start Footer Widget Item */}
                <div
                  className="col-lg-3 col-md-6 wow animated fadeInUp"
                  data-wow-delay="0.3s"
                >

                </div>
                {/*End Footer Widget Item*/}
                {/*Start Footer Widget Item*/}
                <div
                  className="col-lg-3 col-md-6 wow animated fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <div className="footer-widget-item mar-t50 style-two">
                    <div className="title">
                      <h2>{L("recent_news")}</h2>
                    </div>
                    <div className="footer-widget-news">
                      <ul>
                        {
                          Object.values(page ?? {})?.map(val => {
                              return (
                                  <li key={val.id}>
                                    <div className="img-box">
                                      <img
                                          src={image_base_url + val.image}
                                          width={'150'}
                                          height={'150'}
                                          alt=""
                                      />
                                    </div>
                                    <div className="text-box">
                                      <p>
                                        <span className="far fa-calendar-alt" />
                                        {prettyDate(val.date)}
                                      </p>
                                      <h6>
                                        <Link to={'/blog/more-blog/' + val.id}>
                                          {val.title}
                                        </Link>
                                      </h6>
                                    </div>
                                  </li>
                              )
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </div>
                {/*End Footer Widget Item*/}
                {/*Start Footer Widget Item*/}
                <div
                  className="col-lg-3 col-md-6 wow animated fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <div className="footer-widget-item-2 mar-l13">
                    <div className="title">
                      <h2>{L("newsletter")}</h2>
                    </div>
                    <div className="footer-widget-newsletter">
                      <div className="social-link">
                        <ul>
                          <li>
                            <a href="https://www.facebook.com/profile.php?id=61554710988003" target={'_blank'}>
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>

                          <li>
                            <a href="https://www.youtube.com/channel/UCsg0Zfv-dKV3ciobJRSnzdg" target={'_blank'}>
                              <i className="fab fa-youtube" />
                            </a>
                          </li>
                          <li>
                            {/*<a href="https://wa.me/+37443120055" target={'_blank'}>
                              <i className="fab fa-whatsapp" />
                            </a>*/}
                          </li>
                          <li>
                            {/*<a href="https://t.me/+37443120055" target={'_blank'}>
                              <i className="fab fa-telegram" />
                            </a>*/}
                          </li>
                          <li >
                            {/*<a href="viber://chat?number=+37443120055" target={'_blank'}>
                              <i className="fab fa-viber" />
                            </a>*/}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/*End Footer Widget Item*/}
              </div>
            </div>
          </div>
        </div>
        {/*Start Main Footer Bottom */}
        <div className="main-footer-bottom">
          <div className="theme_container">
            <div className="main-footer-bottom-inner">
              <div className="text mx-auto">
                <p>
                   © {new Date().getFullYear()} {L("copyright_text")}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/*End Main Footer Bottom */}
      </footer>
    </Fragment>
  );
};
export default Footer;
