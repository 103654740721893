import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const payment = createSlice({
    name: "payment",
    initialState: initialProfileState,
    reducers: {
        setPayment: (state, {payload}) => {
           return payload;
        }
    }
});

export const {setPayment} = payment.actions;

export default payment.reducer;