import {L} from "../helpers/lang/lang";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setLang} from "../redux/slices/lang";
import {conn} from "../helpers/api";
import {setFoundationPopUp} from "../redux/slices/pageFoundationPopUp";
import {getFoundationPopUp} from "../redux/selectors/pageFoundationPopUpSelector";
import {setPayment} from "../redux/slices/pagePayment";
import {Link} from "react-router-dom";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


export default function DonationPopUp(props) {

    const dispatch = useDispatch();

    // const [submit, setSubmit] = useState(0);

    // useEffect(()=>{
    //     const amount = document.querySelector('.money_input').value;
    //     const name = document.querySelector('.name').value;
    //     const address = document.querySelector('.address').value;
    //     const email = document.querySelector('.donation_email_content').value;
    //     const city = document.querySelector('.city').value;
    //     const zip_postal_code = document.querySelector('.zip_postal_code').value;
    //     const selected_foundation = document.querySelector('.selected_foundation').value;
    //
    //     const payment = {
    //         amount: amount,
    //         name: name,
    //         address: address,
    //         email: email,
    //         city: city,
    //         zip_postal_code: zip_postal_code,
    //         selected_foundation: selected_foundation
    //     };
    //
    //     conn("payment", {payment}).then(res=>{
    //
    //     })
    // }, [submit])

    // let [status, setStatus] = useState(false);
    //
    // useEffect(()=>{
    //     dispatch(setLang(localStorage?.getItem("cur-lang") ?? "en"));
    //
    //     if (status === true) {
    //         conn("foundationpopup", {}).then(res=>{
    //             dispatch(setFoundationPopUp(res.foundationpopup));
    //         })
    //     } else {
    //         conn("foundationpopup", {}).then(res=>{
    //             dispatch(setFoundationPopUp({}));
    //         })
    //     }
    // }, [status])
    //
    // const page = useSelector(getFoundationPopUp());

    return (
        <>
            <div className="donation_big_box">
                <div className="donation_pop_up">
                    {/*<div className="donating_amount">*/}
                    {/*    <h3>{L("donating_amount")}</h3>*/}
                    {/*    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 money_count">*/}
                    {/*        <input type="number" className="donation_input money_input" placeholder={L("amount_count")} required />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="close_pop_up_btn" onClick={() => {
                       props.onClose();
                    }}><h5>X</h5></div>

                    <div className="two_boxes">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-12">
                                    {/*<h3>{L("personal_information")}</h3>*/}
                                    {/*<input type="text" className="donation_input name" placeholder={L("name")} required />*/}
                                    {/*<input type="text" className="donation_input address" placeholder={L("address")} required />*/}
                                    {/*<input type="email" className="donation_input donation_email_content" placeholder={L("email")} required />*/}
                                    {/*<input type="text" className="donation_input city" placeholder={L("city")} required />*/}
                                    {/*<input type="text" className="donation_input zip_postal_code" placeholder={L("zip_postal_code")} required />*/}
                                    {/*<br />*/}
                                    <div className="support-text h3">{L("support_artsakh")}</div>
                                    <div className="support-text">{L("transferring_project_name")}</div>

                                    <Tabs>
                                        <TabList>
                                            <Tab>AMD</Tab>
                                            <Tab>RUB</Tab>
                                            <Tab>USD</Tab>
                                            <Tab>EUR</Tab>
                                        </TabList>
                                        <TabPanel>
                                            <h6 className={'tab_text'}>
                                                <p>ՙՙԱՄԻՕ ԲԱՆԿ՚՚ ՓԲԸ</p>
                                                <p>Հասցե: 0010, ՀՀ ք.Երևան, Նալբանդյան 48</p>
                                                <p>Կազմակերպության անվանումը՝</p>
                                                <p> ՙՙՍՓՅՈՒՌՔԻ ՈՒԺԸ՚՚ ԲԱՐԵԳՈՐԾԱԿԱՆ ՀԱՍԱՐԱԿԱԿԱՆ ԿԱԶՄԱԿԵՐՊՈՒԹՅՈՒՆ</p>
                                                <p>ՀՎՀՀ 02901226</p>
                                                <p>ՀՀ 1150015455465496</p>
                                            </h6>
                                        </TabPanel>
                                        <TabPanel>
                                            <h6 className={'tab_text'}>
                                                <p>Банк корреспондент: АО “РАЙФФАЙЗЕНБАНК”</p>
                                                <p>Москва, Россия</p>
                                                <p>К/к: 30101810200000000700</p>
                                                <p>БИК: 044525700</p>
                                                <p>Банк получателя: «АМИО БАНК» ЗАО</p>
                                                <p>Ереван, Армения 0010</p>
                                                <p>Адрес: ул. Налбандяна 48</p>
                                                <p>Для кредитования: Получатель: “ТHE POWER OF DIASPORA” CHNGO</p>
                                                <p>счет получателя: 1150011188318344</p>
                                            </h6>
                                        </TabPanel>
                                        <TabPanel>
                                            <h6 className={'tab_text'}>
                                                <p>Intermediary Bank: SWIFT: BAGAGE22</p>
                                                <p>BANK OF GEORGIA</p>
                                                <p>Tbilisi, Georgia</p>
                                                <p>Beneficiary’s Bank: SWIFT: ARMNAM22 ”AMIO BANK” CJSC</p>
                                                <p>48, Nalbandyan st.</p>
                                                <p>Yerevan, Armenia</p>
                                                <p>Beneficiary: “ТHE POWER OF DIASPORA” CHNGO</p>
                                                <p>USD Account number 1150014487907973</p>
                                                <p>with ”AMIO BANK” CJSC</p>
                                            </h6>
                                        </TabPanel>
                                        <TabPanel>
                                            <h6 className={'tab_text'}>
                                                <p>Intermediary Bank: SWIFT BIC: POSOIT22</p>
                                                <p>BANCA POPOLARE DI</p>
                                                <p>SONDRIO, Sondrio, Italy</p>
                                                <p>Beneficiary’s Bank: SWIFT BIC: ARMNAM22</p>
                                                <p>”AMIO BANK” CJSC</p>
                                                <p>YEREVAN, ARMENIA 48, Nalbandyan st.</p>
                                                <p>Beneficiary: “ТHE POWER OF DIASPORA” CHNGO</p>
                                                <p>EUR Account Number 1150009796056014</p>
                                                <p>with ”AMIO BANK” CJSC</p>
                                            </h6>
                                        </TabPanel>

                                    </Tabs>
                                </div>
                                {/*<div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">*/}
                                {/*    <h3>{L("select_foundation")}</h3>*/}
                                {/*    <select className="donation_input selected_foundation" defaultValue={L("select_foundation")} required onClick={()=>{*/}
                                {/*        setStatus(status = true);*/}
                                {/*    }}>*/}
                                {/*        <option selected disabled value={''}>{L("select_foundation")}</option>*/}
                                {/*        {*/}
                                {/*            Object.values(page ?? {})?.map(val => {*/}
                                {/*                return (*/}
                                {/*                    <option value={val.id}>{val.title}</option>*/}
                                {/*                )*/}
                                {/*            })*/}
                                {/*        }*/}
                                {/*    </select>*/}

                                {/*    <div className="from-button donation_pop_up_btn_box">*/}
                                {/*    <button type={'submit'} onClick={()=>{*/}
                                {/*        setSubmit(submit + 1);*/}
                                {/*        setTimeout(()=>{*/}
                                {/*            document.querySelector('.money_input').value = '';*/}
                                {/*            document.querySelector('.name').value = '';*/}
                                {/*            document.querySelector('.address').value = '';*/}
                                {/*            document.querySelector('.donation_email_content').value = '';*/}
                                {/*            document.querySelector('.city').value = '';*/}
                                {/*            document.querySelector('.zip_postal_code').value = '';*/}
                                {/*            document.querySelector('.selected_foundation').value = '';*/}
                                {/*            document.querySelector('.close_pop_up_btn').click();*/}
                                {/*        }, 2000)*/}
                                {/*    }}>*/}
                                {/*        <a className="primary_btn donation_pop_up_btn">*/}
                                {/*            {L("donation")}*/}
                                {/*            <i className="far fa-heart" />*/}
                                {/*        </a>*/}
                                {/*    </button>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
