import { Swiper, SwiperSlide } from "swiper/react";
import { fiveItemCarousel } from "../sliderProps";
import {image_base_url} from "../helpers";
import bg_partners from "../public/assets/images/background/cta.jpg";
import {getPage} from "../redux/selectors/pageSelector";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {conn} from "../helpers/api";
import {setPartners} from "../redux/slices/pagePartners";
import {getPartners} from "../redux/selectors/pagePartnersSelector";
import {setAboutMenuItems} from "../redux/slices/aboutMenu";

const PatnerLogoSlider = () => {

  const page = useSelector(getPartners());
    const dispatch = useDispatch();

  return (
      <section className="partner">
        <div
            className="bg"
            style={{ backgroundImage: "url(" + bg_partners + ")" }}
        />
        <div className="theme_container">
          <Swiper
              {...fiveItemCarousel}
              className="five-item-carousel owl-theme owl-carousel"
          >
            {

                Object.values(page ?? {})?.map(val => {
                return (
                    <>
                      <SwiperSlide className="image" style={{height: "60px", textAlign: "center"}}>
                        <img src={image_base_url + val.image} style={{height: "100%"}} alt=""/>
                      </SwiperSlide>
                    </>
                )
              })
            }
          </Swiper>
        </div>
      </section>
  );
};
export default PatnerLogoSlider;
