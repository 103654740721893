import React, {useEffect, useState} from 'react';
import "./CardOrder.scss";
import {L} from "../../helpers/lang/lang";
import {Link} from "react-router-dom";
import {conn} from "../../helpers/api";
import {cards} from "../CardsSlider/CardSlider";

const CardOrder = ({onClose, onSend}) => {

    const [data, setData] = useState({});
    const [err, setErr] = useState(false);
    const [selected, setSelected] = useState(0);


    useEffect(() => {
        console.log("data", data);
    }, [data])


    const sendMessage = (data) => {

        if (data?.name?.length && data?.email?.length && data?.phone?.length && data?.country?.length && data?.city?.length && data?.address?.length && data?.zip?.length) {
            conn("sendmessage/order", {...data, theme: "Card order"}).then(res => {
                setData({});
                onClose();
            });
            onSend();
        } else {
            setErr(true);
        }
    }

    return (
        <div className={"card-order"}>
            <div className="card-order-in">
                <div className="c-o-title">{L("order-card")}</div>

                <div className="card-slider-icons">
                    {cards.map((v, i)=>{
                        return  <div onClick={()=>setSelected(i)} key={i} className={"card-slider-icon" + (selected === i? " active": "")}>
                            <img src={require("./../../public/assets/images/cards/" + v.image)}/>
                        </div>
                    })}

                </div>
                <input onInput={(e) => setData(prev => {
                    return {
                        ...prev, name: e.target.value
                    }
                })} className={"c-o-input"} placeholder={L("your_name") + "*"}/>

                <input onInput={(e) => setData(prev => {
                    return {
                        ...prev, phone: e.target.value
                    }
                })} className={"c-o-input"} placeholder={L("phone_number") + "*"}/>

                <input onInput={(e) => setData(prev => {
                    return {
                        ...prev, email: e.target.value
                    }
                })} className={"c-o-input"} placeholder={L("your_email") + "*"}/>
                <input onInput={(e) => setData(prev => {
                    return {
                        ...prev, country: e.target.value
                    }
                })} className={"c-o-input"} placeholder={L("country") + "*"}/>

                <input onInput={(e) => setData(prev => {
                    return {
                        ...prev, city: e.target.value
                    }
                })} className={"c-o-input"} placeholder={L("city") + "*"}/>

                <input onInput={(e) => setData(prev => {
                    return {
                        ...prev, address: e.target.value
                    }
                })} className={"c-o-input"} placeholder={L("address") + "*"}/>

                <input onInput={(e) => setData(prev => {
                    return {
                        ...prev, zip: e.target.value
                    }
                })} className={"c-o-input"} placeholder={L("zip_postal_code") + "*"}/>

                <div className={"card-price"}>{L("price")} {prettyPrice(cards[selected].price)}</div>


                {err ? <div className={"o-err"}>{L("fill-in")}</div> : <div className={"o-space"}></div>}

                <div className={"buttons-bl"} onClick={() => {
                    sendMessage({...data, type: cards[selected].type});

                }}>
                    <div className="link-btn">
                        <Link>
                            <a className="primary_btn">
                                {L("order-card")}
                                <i className="far fa-heart"/>
                            </a>
                        </Link>
                    </div>
                </div>
                <div className={"button-bl-2"} onClick={onClose}>
                    {L("close")}
                </div>

            </div>
        </div>
    );
};

export default CardOrder;

export const prettyPrice = (price)=>{
    const formatter = new Intl.NumberFormat();
    return  formatter.format(price) + " ֏";
}
