import { Swiper, SwiperSlide } from "swiper/react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";
import { fiveItemCarousel } from "../sliderProps";
import {L} from "../helpers/lang/lang";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {conn} from "../helpers/api";
import {setAboutMenuItems} from "../redux/slices/aboutMenu";
import {setPage} from "../redux/slices/pageSlice";
import {useDispatch, useSelector} from "react-redux";
import {setAllNews, setMany} from "../redux/slices/pageNews";
import {setLang} from "../redux/slices/lang";
import {image_base_url} from "../helpers";
import {selectAllNews} from "../redux/selectors/pageSelectorNews";
import {prettyDate} from "../helpers/date";
import {setNewsGallery} from "../redux/slices/pageNewsGallery";
import {getNewsGallery} from "../redux/selectors/pageNewsGallery";
import {setFooterNews} from "../redux/slices/pageFooterNews";
import {setSearchNews} from "../redux/slices/pageSearchNews";
import {getSearchNews} from "../redux/selectors/pageSearchNewsSelector";

const BlogStandard = () => {

  const dispatch = useDispatch();
  const [from, setFrom] = useState(0);
  const [count_all, setCount_all] = useState(0);

  useEffect(()=>{
    dispatch(setLang(localStorage?.getItem("cur-lang") ?? "en"));

    conn("newsgallery", {}).then(res=>{
      dispatch(setNewsGallery(res.newsgallery));
    })

    conn("searchnews", {}).then(res=>{
      dispatch(setSearchNews(res.search_result_news));
    })
  }, [])

  const pageNewsGallery = useSelector(getNewsGallery());

  useEffect(()=>{
    conn("news", {from: from}).then(res=>{
      dispatch(setAboutMenuItems(res.about_menu));
      dispatch(setFooterNews(res.footer_news));
      setCount_all(res.count_all);
      dispatch(setMany(res.news));
    })
  }, [from]);

  const pageNews = useSelector(selectAllNews);
  const pageSearch = useSelector(getSearchNews());

  return (
    <Layouts>
      <PageBanner pageName={L("blog")} />
      <section className="blog-grid-section">
        <div className="theme_container">
          <div className="row">
            <div className="col-lg-8">
                  <div className="blog-grid-block">
                    {
                      pageNews?.map(val => {
                        return (
                            <>
                              <div className="image">
                                <img src={image_base_url + val.image} alt="" />
                              </div>
                              <div className="content-block">
                                <div className="date">
                                  <i className="far fa-calendar-alt" />
                                  {prettyDate(val.date)}
                                </div>
                                <h4>
                                  {val.title}
                                </h4>
                                <div className="text">
                                  {val.text}
                                </div>
                                <div className="blog-grid-button">
                                  <Link to={"/blog/more-blog/" + val.id}>
                                    {L("read_more")}
                                    <i className="far fa-arrow-right" />
                                  </Link>
                                </div>
                              </div>
                            </>
                        )
                      })
                    }
                    <div className={'post-cat-three_new_button'} onClick={() => {
                      setFrom(prev=> prev + 10);
                    }}>{L("show_more")}</div>
                  </div>
            </div>
            <div className="col-lg-4">
              <div className="blog-grid-block style-four">
                {/*Start Sidebar Block*/}
                <div className="title">
                  <h3>
                    {L("search_now")}
                    <span className="right-img">
                      <img src="assets/images/resource/icon-4.png" alt="" />
                    </span>
                  </h3>
                </div>
                <div className="sidebar-search-box">
                  <form
                    className="search-form"
                    method={'get'}
                    onSubmit={(e)=>{
                      e.preventDefault();
                    }}
                  >
                    <input type="text" className="search_title_two" placeholder={L("search_now") + "..."} onKeyUp={(e)=>{
                      const searchBox = document.querySelector(".search");
                      searchBox.innerHTML = "";
                      for (let i = 0; i <= pageSearch?.length; i++) {
                        if (pageSearch?.[i]) {
                          if (document.querySelector(".search_title_two").value == pageSearch?.[i].title) {
                            const boxContent = document.createElement('a');
                            const boxImgDiv = document.createElement('div');
                            const bigBox = document.createElement('div');
                            bigBox.setAttribute('class', 'search_big_box');
                            boxContent.setAttribute('class', 'search_box_content');

                            boxContent.setAttribute('href', '/blog/more-blog/' + pageSearch?.[i].id);

                            let boxImg = document.createElement('img');
                            boxImg.setAttribute("src", image_base_url + pageSearch?.[i].image);
                            boxImg.setAttribute("width", "80");
                            boxImg.setAttribute("height", "80");
                            boxImgDiv.appendChild(boxImg);

                            let boxDate = document.createElement('div');
                            boxDate.innerHTML = prettyDate(pageSearch?.[i].date);
                            boxDate.setAttribute('class', 'filtred_box_text');
                            boxContent.appendChild(boxDate);


                            let boxTitle = document.createElement('div');
                            boxTitle.innerHTML = pageSearch?.[i].title;
                            boxTitle.setAttribute('class', 'filtred_box_text');
                            boxContent.appendChild(boxTitle);
                            searchBox.appendChild(bigBox);

                            bigBox.appendChild(boxImgDiv);
                            bigBox.appendChild(boxContent);
                          }
                        }
                      }
                    }} />
                  </form>
                </div>
                {/*End Sidebar Block*/}
              </div>
              {/*Start Sidebar Block*/}

              <div
                  className="sidebar-block wow fadeInUp animated search_fixed"
                  data-wow-delay="0.2s"
                  data-wow-duration="1200ms"
              >
                <div className="blog-standard-block-three">
                  <div className="search">

                  </div>
                </div>
              </div>



              {/*end search */}
              <div
                className="sidebar-block wow fadeInUp animated"
                data-wow-delay="0.2s"
                data-wow-duration="1200ms"
              >
                <div className="blog-standard-block-three">
                  <div className="title">
                    <h4>
                      {L("recent_news")}
                      <img src="assets/images/resource/icon-4.png" alt="" />
                    </h4>
                  </div>
                  <ul className="popular-feeds">
                    {
                      [...pageNews]?.slice(0, 3).map(val => {
                        return (
                            <li>
                              <div className="inner">
                                <div className="img-box">
                                  <img
                                      src={image_base_url + val.image}
                                      className={'h-100'}
                                      alt="Awesome Image"
                                  />
                                  <div className="overlay-content">
                                    <Link to={'/blog/more-blog/' + val.id}>
                                      <i className="fa fa-link" aria-hidden="true"/>
                                    </Link>
                                  </div>
                                </div>
                                <div className="title-box">
                                  <p>
                                    <span className="far fa-calendar-alt"/>
                                    {prettyDate(val.date)}
                                  </p>
                                  <h4>
                                    <Link to={'/blog/more-blog/' + val.id}>
                                      {val.title}
                                    </Link>
                                  </h4>
                                </div>
                              </div>
                            </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>
              {/*End Sidebar Block*/}
              {/* Start Sidebar Block */}
              <div className="event-details-block-two">
                <h4>
                  {L("gallery_photo")}
                  <img src="assets/images/resource/icon-4.png" alt="" />
                </h4>
                {
                  Object.values(pageNewsGallery ?? {})?.map(val => {
                    return (
                        <div className="inner-box">
                          <div className="wrapper-box">
                            <div className="image">
                              <img
                                  src={image_base_url + val.image}
                                  alt=""
                              />
                              <div className="overlay-link">
                                <a
                                    href={image_base_url + val.image}
                                    className="lightbox-image"
                                    data-fancybox="gallery"
                                    target={'_blank'}
                                >
                                  <span className="fab fa-instagram"/>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                    )
                  })
                }
              </div>
              {/* End Sidebar Block */}
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};
export default BlogStandard;
