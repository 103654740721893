import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const footer_news = createSlice({
    name: "footer_news",
    initialState: initialProfileState,
    reducers: {
        setFooterNews: (state, {payload}) => {
            return payload;
        }
    }
});

export const {setFooterNews} = footer_news.actions;

export default footer_news.reducer;