import {Fragment, useEffect, useState} from "react";
import {L} from "../../helpers/lang/lang";
import {image_base_url} from "../../helpers";
import {prettyDate} from "../../helpers/date";
import {conn} from "../../helpers/api";
import {setSearch} from "../../redux/slices/pageSearch";
import {getSearch} from "../../redux/selectors/pageSearchSelector";
import {useDispatch, useSelector} from "react-redux";
import {setLang} from "../../redux/slices/lang";

const HeaderSearch = ({ open, close }) => {

  const dispatch = useDispatch();
  let [status, setStatus] = useState(false);

  useEffect(()=>{
    dispatch(setLang(localStorage?.getItem("cur-lang") ?? "en"));

    if (status === true) {
      conn("search", {}).then(res => {
        dispatch(setSearch(res.search_result));
      })
    } else {
      conn("search", {}).then(res => {
        dispatch(setSearch({}));
      })
    }
  }, [status])

  const pageSearch = useSelector(getSearch());

  return (
    <Fragment>
      <div
        id="header-main-search"
        className={`header-main-search ${open ? "popup-visible" : ""}`}
      >
        <div className="close-search primary_btn" onClick={() =>{
          setStatus(status = false);
          const searchBox = document.querySelector(".search_two");
          searchBox.innerHTML = "";
          document.querySelector(".search_title").value = '';
          close();
        }}>
          <i className="far fa-times" />
        </div>
        <div className="popup-inner">
          <div className="search-form">
            <form
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="form-group">
                <fieldset>
                  <input
                    type="search"
                    className="form-control search_title"
                    name="search-input"
                    placeholder={L("type_enter")}
                    onClick={()=>{
                      setStatus(status = true);
                    }}
                    onKeyUp={(e)=>{
                      const searchBox = document.querySelector(".search_two");
                      searchBox.innerHTML = "";
                      for (let i = 0; i <= pageSearch?.length; i++) {
                        if (document.querySelector(".search_title").value == pageSearch?.[i].title) {
                          const boxContent = document.createElement('a');
                          const boxImgDiv = document.createElement('div');
                          const bigBox = document.createElement('div');
                          bigBox.setAttribute('class', 'search_big_box');
                          boxContent.setAttribute('class', 'search_box_content');

                          if (pageSearch?.[i].type == 'foundation') {
                            boxContent.setAttribute('href', '/donation-details/' + pageSearch?.[i].id);
                          } else {
                            boxContent.setAttribute('href', '/blog/more-blog/' + pageSearch?.[i].id);
                          }

                          let boxImg = document.createElement('img');
                          boxImg.setAttribute("src", image_base_url + pageSearch?.[i].image);
                          boxImg.setAttribute("width", "80");
                          boxImg.setAttribute("height", "80");
                          boxImgDiv.appendChild(boxImg);

                          let boxDate = document.createElement('div');
                          boxDate.innerHTML = prettyDate(pageSearch?.[i].date);
                          boxDate.setAttribute('class', 'filtred_box_text');
                          boxContent.appendChild(boxDate);


                          let boxTitle = document.createElement('div');
                          boxTitle.innerHTML = pageSearch?.[i].title;
                          boxTitle.setAttribute('class', 'filtred_box_text');
                          boxContent.appendChild(boxTitle);
                          searchBox.appendChild(bigBox);

                          bigBox.appendChild(boxImgDiv);
                          bigBox.appendChild(boxContent);
                        }
                        }
                    }}
                  />
                </fieldset>
              </div>
            </form>
          </div>
        </div>
        <div className="search_two"></div>
      </div>
    </Fragment>
  );
};
export default HeaderSearch;
