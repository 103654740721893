import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const about_menu = createSlice({
    name: "about_menu",
    initialState: initialProfileState,
    reducers: {
        setAbout: (state, {payload}) => {
            return payload;
        }
    }
});

export const {setAbout} = about_menu.actions;

export default about_menu.reducer;