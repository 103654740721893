import {createEntityAdapter, createSlice, Slice} from "@reduxjs/toolkit";

export const aboutMenuAdapter = createEntityAdapter({
    selectId: (menu) => menu.id,
    sortComparer: (a, b) => a.sort - b.sort,
});


const aboutMenu = createSlice({
    name: "about_list",
    initialState: aboutMenuAdapter.getInitialState([]),
    reducers: {
        setAboutMenuItems: aboutMenuAdapter.setAll,
    }
});

export const {setAboutMenuItems} = aboutMenu.actions;

export default aboutMenu.reducer;