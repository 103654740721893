import {Link, useParams} from "react-router-dom";
import Counter from "./Counter";
import {L} from "../helpers/lang/lang";
import {useState} from "react";
import CardOrder from "./CardOrder/CardOrder";
import DonationPopUp from "./donation_pop_up";

const FooterCounter = () => {
 const [order, setOrder] = useState(false);
  // let amountBtnBg = "";
  //
  // function verifyAmountBtnBg() {
  //   if (amountBtnBg == "li_one") {
  //     document.querySelector('.amount_btn_one2').setAttribute('id', 'selected_amount_btn');
  //     document.querySelector('.amount_btn_two2').removeAttribute('id');
  //     document.querySelector('.amount_btn_tree2').removeAttribute('id');
  //     document.querySelector('.amount_btn_for2').removeAttribute('id');
  //   } else if (amountBtnBg == "li_two") {
  //     document.querySelector('.amount_btn_one2').removeAttribute('id');
  //     document.querySelector('.amount_btn_two2').setAttribute('id', 'selected_amount_btn');
  //     document.querySelector('.amount_btn_tree2').removeAttribute('id');
  //     document.querySelector('.amount_btn_for2').removeAttribute('id');
  //   } else if (amountBtnBg == "li_tree") {
  //     document.querySelector('.amount_btn_one2').removeAttribute('id');
  //     document.querySelector('.amount_btn_two2').removeAttribute('id');
  //     document.querySelector('.amount_btn_tree2').setAttribute('id', 'selected_amount_btn');
  //     document.querySelector('.amount_btn_for2').removeAttribute('id');
  //   } else if (amountBtnBg == "li_for") {
  //     document.querySelector('.amount_btn_one2').removeAttribute('id');
  //     document.querySelector('.amount_btn_two2').removeAttribute('id');
  //     document.querySelector('.amount_btn_tree2').removeAttribute('id');
  //     document.querySelector('.amount_btn_for2').setAttribute('id', 'selected_amount_btn');
  //   }
  // }

  return (
    <div className="section-footer-counter-section">
      <div className="theme_container">
        <div className="row">
          <div className="col-lg-6">
            <div className="footer-counter-block">
              <div className="count-outer count-box">
                {/* <span
                  className="count-text"
                  data-speed={3000}
                  data-stop={25360}
                >
                  0
                </span> */}
                {/*<Counter end={3000} />*/}
                {/*<span className="plus">+</span>*/}
                <span className="crl3 mt-4">{L("make_donation")}</span>
              </div>
            </div>
          </div>
          <div className="col-lg-6 text-end">
            <div className="project-form style-two donation-form-one-form-wrap">
              {/*<ul className="donation-amount">*/}
              {/*  <li className={'amount_btn_one2'} key={1} value={1000} onClick={(e)=>{*/}
              {/*    document.querySelector('.money_input').value = e.target.value;*/}
              {/*    amountBtnBg = "li_one";*/}
              {/*    verifyAmountBtnBg();*/}
              {/*  }}>$1000</li>*/}
              {/*  <li className={'amount_btn_two2'} key={2} value={500} onClick={(e)=>{*/}
              {/*    document.querySelector('.money_input').value = e.target.value;*/}
              {/*    amountBtnBg = "li_two";*/}
              {/*    verifyAmountBtnBg();*/}
              {/*  }}>$500</li>*/}
              {/*  <li className={'amount_btn_tree2'} key={3} value={100} onClick={(e)=>{*/}
              {/*    document.querySelector('.money_input').value = e.target.value;*/}
              {/*    amountBtnBg = "li_tree";*/}
              {/*    verifyAmountBtnBg();*/}
              {/*  }}>$100</li>*/}
              {/*  <li className={'amount_btn_for2'} key={4} value={50} onClick={(e)=>{*/}
              {/*    document.querySelector('.money_input').value = e.target.value;*/}
              {/*    amountBtnBg = "li_for";*/}
              {/*    verifyAmountBtnBg();*/}
              {/*  }}>$50</li>*/}
              {/*</ul>*/}
              <div className="from-button">
                                        <span onClick={()=>setOrder(true)}>
                                            <a className="primary_btn-one">
                                                {L("make_donation")}
                                              <i className="far fa-heart" />
                                            </a>
                                        </span>
              </div>
            </div>
          </div>
        </div>
      </div>
        {order? <DonationPopUp onClose={()=>setOrder(false)}/>: null}
    </div>
  );
};
export default FooterCounter;
