import {Link} from "react-router-dom";
import PageBanner from "../components/PageBanner";
import PatnerLogoSlider from "../components/PatnerLogoSlider";
import Layouts from "../layouts/Layouts";
import {L} from "../helpers/lang/lang";
import {image_base_url} from "../helpers";
import {useEffect, useState} from "react";
import {setLang} from "../redux/slices/lang";
import {conn} from "../helpers/api";
import {setAboutMenuItems} from "../redux/slices/aboutMenu";
import {setFooterNews} from "../redux/slices/pageFooterNews";
import {setOneFoundation} from "../redux/slices/pageFoundation";
import {setNewsGallery} from "../redux/slices/pageNewsGallery";
import {setPartners} from "../redux/slices/pagePartners";
import {useDispatch} from "react-redux";
import {setMessage} from "../redux/slices/pageContact";
import emailjs from "@emailjs/browser";
import $ from "jquery";
import {useRef} from "react";

const Contact = () => {

  const dispatch = useDispatch();
  const form = useRef();

  useEffect(()=>{
    dispatch(setLang(localStorage?.getItem("cur-lang") ?? "en"));
  }, [])

  useEffect(()=>{
    conn("partners", {}).then(res=>{
      dispatch(setAboutMenuItems(res.about_menu));
      dispatch(setFooterNews(res.footer_news));
      dispatch(setPartners(res.partners));
    })
  }, []);

  const [submit, setSubmit] = useState(0);

  const sendMessage = ()=>{
    const name = document.querySelector('.message_name').value;
    const email = document.querySelector('.message_email').value;
    const phone = document.querySelector('.phone').value;
    const message = document.querySelector('.message').value;

    const sendMessage = {
      name: name,
      email: email,
      phone: phone,
      message: message
    };

    if(sendMessage.name.length && sendMessage.message?.length && sendMessage.email?.length && sendMessage.phone?.length){
      conn("sendmessage/message", {theme: "Message from site", ...sendMessage}).then(res=>{
        document.querySelector('.message_name').value = '';
        document.querySelector('.message_email').value = '';
        document.querySelector('.phone').value = '';
        document.querySelector('.message').value = '';
        alert(L("message_sent"));
      });
    }else{
      alert(L("fill-in"))
    }



  }

  return (
    <Layouts>
      <PageBanner pageName={L("contact")} />
      {/* our contact section */}
      <section className="our-contact-section">
        <div className="theme_container">
          <div className="sub-title mb-20">
            <span className="title-sep-left">
              <img src="assets/images/resource/icon-4.png" alt="" />
            </span>
            {L("contact_us")}
          </div>
          <h2 className="sec-title mb-56">
            {L("contact_text")}
          </h2>
          <div className="row">
           {/* <div className="col-lg-4 col-md-6">
              <div className="our-contact-block">
                <div className="icon">
                  <i className="flaticon-map" />
                </div>
                <div className="content">
                  <h5>{L("address")}</h5>
                  <div className="text">
                    {L("address_text")}
                  </div>
                </div>
              </div>
            </div>*/}
            <div className="col-lg-4 col-md-6">
              <div className="our-contact-block">
                <div className="icon">
                  <i className="flaticon-envelope" />
                </div>
                <div className="content">
                  <h5>{L("email_address")}</h5>
                  <div className="text">
                    info@powerofdiaspora.com
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              {/*<div className="our-contact-block">
                <div className="icon">
                  <i className="flaticon-phone" />
                </div>
                <div className="content">
                  <h5>{L("phone_number")}</h5>
                  <div className="text">
                    +374 43 12 00 55
                  </div>
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </section>
      {/* contact form section */}
      <section className="contact-form-section">
        <div className="theme_container">
          <div className="contact-block">
            <div className="contact-form-block style-three">
              <h4 className={'mb-5'}>{L("send_message")}</h4>
              {/*Contact Form*/}
                <div className="row">
                    <div className="form-group col-md-6">
                      <input
                          type="text"
                          className="form-control message_name"
                          id={'toName'}
                          placeholder={L("your_name")}
                          required
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <input
                          type="email"
                          className="form-control message_email"
                          placeholder={L("your_email")}
                          required
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <input
                          type="text"
                          className="form-control phone"
                          placeholder={L("phone_number")}
                          required
                      />
                    </div>
                    <div className="form-group col-md-12">
                    <textarea
                        className="form-control message"
                        placeholder={L("write_message")}
                    />
                    </div>
                    <div className="form-group col-md-12 text-center">
                      <button
                          className="primary_btn btn-style-one"
                          type={'submit'}
                          onClick={()=>{
                            sendMessage();
                          }}
                      >
                        <span>{L("send")}</span>
                      </button>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </section>
      <PatnerLogoSlider />
    </Layouts>
  );
};
export default Contact;
