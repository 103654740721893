const month = {
    hy: {
        0: "Հունվար",
        1: "Փետրվար",
        2: "Մարտ",
        3: "Ապրիլ",
        4: "Մայիս",
        5: "Հունիս",
        6: "Հուլիս",
        7: "Օգոստոս",
        8: "Սեպտեմբեր",
        9: "Հոկտեմբեր",
        10: "Նոյեմբեր",
        11: "Դեկտեմբեր"
    },
    en: {
        0: "January",
        1: "February",
        2: "March",
        3: "April",
        4: "May",
        5: "June",
        6: "July",
        7: "August",
        8: "September",
        9: "October",
        10: "November",
        11: "December"
    },
    ru: {
        0: "Январь",
        1: "Февраль",
        2: "Март",
        3: "Апрель",
        4: "Май",
        5: "Июнь",
        6: "Июль",
        7: "Август",
        8: "Сентябрь",
        9: "Октябрь",
        10: "Ноябрь",
        11: "Декабрь"
    },
}

const getZero = (x)=>{
    return x < 10? "0" + x: x;
}

export const prettyDate = (date)=>{
    const jsDate = new Date(date);
    const now = new Date();
    const lang = localStorage.getItem("cur-lang") ?? "en";
    return `${now.getFullYear() !== jsDate.getFullYear()? jsDate.getFullYear() + " " : "" }${month[lang][jsDate.getMonth()]} ${getZero(jsDate.getDate())}`
}

