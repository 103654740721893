import React from "react";
import "./styles/globals.css";
import store from "./redux/stores/store";
import {useSelector} from "react-redux";
import {getPage} from "./redux/selectors/pageSelector";
import {Routes, Route} from "react-router-dom";
import IndexContent from "./pages/index_content";
import BlogStandard from "./pages/blog";
import BlogDetails from "./pages/blog-details";
import About from "./pages/about";
import DonationGrid from "./pages/donation-grid";
import DonationDetails from "./pages/donation-details";
import Contact from "./pages/contact";
import ScrollToTop from "./helpers/scroolTop";

export default function App() {

    ScrollToTop();

  return (
         <>
             <Routes>
                 <Route path={"/"} element={<IndexContent />} />
                 <Route path={"/about/:id"} element={<About />} />
                 <Route path={"/blog"} element={<BlogStandard />} />
                 <Route path={"/blog/more-blog/:id"} element={<BlogDetails />} />
                 <Route path={"/donation-grid"} element={<DonationGrid />} />
                 <Route path={"/donation-details/:id"} element={<DonationDetails />} />
                 <Route path={"/contact"} element={<Contact />} />
             </Routes>
         </>
  );
}
