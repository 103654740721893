import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const search_result_news = createSlice({
    name: "search_result_news",
    initialState: initialProfileState,
    reducers: {
        setSearchNews: (state, {payload}) => {
           return payload;
        }
    }
});

export const {setSearchNews} = search_result_news.actions;

export default search_result_news.reducer;