import React, {useState} from 'react';
import "./CardSlider.scss";
import {Link} from "react-router-dom";
import {L} from "../../helpers/lang/lang";
import CardOrder from "../CardOrder/CardOrder";
import DonationPopUp from "../donation_pop_up";


export const cards = [
    {image: "card-3.png", image2: "card-3-2.png", type: "Orange", price: 15000},
    {image: "card-2.png", image2: "card-2-2.png", type: "Orange-Black", price: 25000},
    {image: "card-1.png", image2: "card-1-2.png", type: "Gold-Black", price: 50000},
]

const CardSlider = () => {

    const [selected, setSelected] = useState(0);
    const [hover, setHover] = useState(false);
    const [order, setOrder] = useState(false);
    const [donate, setDoante] = useState(false);

    return (
        <div className={"card-slider"}>
            <div className="card-slider-in">
            <div className="button-bl">
                <div className="link-btn">
                    <Link>
                        <a className="primary_btn" onClick={()=>setOrder(true)}>
                            {L("order-card")}
                            <i className="far fa-heart" />
                        </a>
                    </Link>
                </div>

            </div>
            <img onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} src={require("./../../public/assets/images/cards/" + (hover? cards[selected].image2: cards[selected].image))} />
        </div>
        <div className="card-slider-icons">
            {cards.map((v, i)=>{
              return  <div onClick={()=>setSelected(i)} key={i} className={"card-slider-icon" + (selected === i? " active": "")}>
                    <img src={require("./../../public/assets/images/cards/" + v.image)}/>
                </div>
            })}

        </div>
            {order ? <CardOrder onClose={()=>setOrder(false)} onSend={()=>setDoante(true)}/> : null}
            {donate ? <DonationPopUp onClose={()=>setDoante(false)}/> : null}
        </div>
    );
};

export default CardSlider;
