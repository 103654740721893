import {Link} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import HeaderSearch from "./HeaderSearch";
import { Blog, Contact, Donation, Home, Page, Portfolio } from "./Menus";
import MobileMenu from "./MobileMenu";
import StickNav from "./StickNav";
import {useDispatch, useSelector} from "react-redux";
import {selectAboutMenu} from "../../redux/selectors/aboutMenu";
import {L, langArr} from "../../helpers/lang/lang";
import {getLang} from "../../redux/selectors/lang";
import mobile_bars from "../../public/assets/images/icons/icon-bar.png";
import Select from "react-select";
import {setLang} from "../../redux/slices/lang";
import {conn} from "../../helpers/api";
import {setSearch} from "../../redux/slices/pageSearch";
import Logo from "../../public/assets/images/powerofdiaspora.png";
import CardOrder from "../../components/CardOrder/CardOrder";
import DonationPopUp from "../../components/donation_pop_up";

const Header = () => {
  const [sideBarToggle, setSideBarToggle] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const aboutMenuList = useSelector(selectAboutMenu);
  const [donate, setDonate] = useState(false);


  const curLang = useSelector(getLang());
  const openMobileMenu = () => {
    document.querySelector("body").classList.add("mobile-menu-visible");
  };
  const closMobileMenu = () => {
    document.querySelector("body").classList.remove("mobile-menu-visible");
  };

  const [order, setOrder] = useState(false);
  const dispatch = useDispatch();

  return (
    <Fragment>
      {donate ? <DonationPopUp onClose={()=>setDonate(false)}/> : null}
      <header className="main-header header-style-two">
        <div className="header-top">
          <div className="header-container-box">
            <div className="wrapper-box">
              <div className="left-column">
                <ul className="contact-info box-style-two">
                  <li>
                    <a href="mailto:info@webmail.com">
                      <i className="far fa-envelope">
                        <span>{L("email")}:</span>
                      </i>
                      info@powerofdiaspora.com
                    </a>
                  </li>
                  <li className="crl-1">|</li>
                </ul>
              </div>
              <div className="right-column box-style-two">
                {/* <div className="text">{L("text_contact_us")}</div> */}
                <ul>
                  <li>
                    {/*<a href="">
                      <span>{L("hotline")} : </span> +374 43 12 00 55
                    </a>*/}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Header Upper */}
        <div className="header-upper">
          <div className="header-container-box">
            <div className="inner-container">
              <div className="left-column">
                <div className="logo">
                  <Link to="/">
                    <a>
                      <img src={Logo} alt="" />
                    </a>
                  </Link>
                </div>
                {/*Nav Box*/}
                <div className="nav-outer">
                  {/*Mobile Navigation Toggler*/}
                  <div
                    className="mobile-nav-toggler"
                    onClick={() => openMobileMenu()}
                  >
                    <img src={mobile_bars} alt="" />
                  </div>
                  {/* Main Menu */}
                  <nav className="main-menu navbar-expand-md navbar-light">
                    <div
                      className="collapse navbar-collapse show clearfix"
                      id="navbarSupportedContent"
                    >
                      <ul className="navigation">
                        <li className="dropdown">
                          <Link to="/">{L("home")}</Link>
                        </li>
                        <li className="dropdown">
                          <Link to="#">{L("about_us")}</Link>
                          <ul>
                           {<Page list={aboutMenuList} />}
                          </ul>
                        </li>
                        <li className="dropdown">
                          <Link to="/donation-grid">{L("donation_grid")}</Link>
                        </li>
                        <li className="dropdown">
                          <Link to="/blog">{L("blog")}</Link>
                        </li>
                        <Contact />
                      </ul>
                    </div>
                  </nav>
                  {/* language */}
                  <div className="language">
                    <Select
                        value={{label: langArr.find(x => x.id === curLang)?.title}}
                        options={langArr.map(lg=>{
                      return{
                        label: lg.title,
                        value: lg.id
                      }
                    })}
                           onChange={(e)=>{
                                 localStorage.setItem("cur-lang", e.value);
                                 window.location.reload();
                           }}
                        className={"custom-select"}
                    />
                  </div>
                </div>
              </div>
              <div className="right-column">
                <div
                  className="search-toggler"
                  onClick={() => setSearchToggle(true)}
                >
                  <i className="far fa-search" />
                </div>
                <div
                  className="menu-bar sidemenu-nav-toggler"
                  onClick={() => setSideBarToggle(true)}
                >
                  <img src="assets/images/icons/icon-menu.png" alt="" />
                </div>
                <a className="primary_btn style-seven" onClick={()=>setDonate(true)}>
                  {L("make_donation")}
                  <i className="far fa-heart" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {order? <CardOrder onClose={()=>setOrder(false)}/>: null}
        {/*End Header Upper*/}
        {/* Sticky Header  */}
        <StickNav
          openMobileMenu={() => openMobileMenu()}
          openSidebar={() => setSideBarToggle(true)}
          openSearchBar={() => setSearchToggle(true)}
        />
        {/* End Sticky Menu */}
        {/* Mobile Menu  */}
        <MobileMenu close={() => closMobileMenu()} />
        {/* End Mobile Menu */}
        <div
          className="nav-overlay"
          style={{ display: sideBarToggle ? "block" : "none" }}
          onClick={() => setSideBarToggle(false)}
        />
      </header>
      <HeaderSearch open={searchToggle} close={() => setSearchToggle(false)} />
    </Fragment>
  );
};
export default Header;
