import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const lang = createSlice({
    name: "lang",
    initialState: initialProfileState,
    reducers: {
        setLang: (state, {payload}) => {
           return payload;
        }
    }
});

export const {setLang} = lang.actions;

export default lang.reducer;