import axios from "axios";
import Store from "../redux/stores/store";

export const conn = (request, data = {})=>new Promise((resolve)=>{
    const headers = {
        lang: Store.getState()?.lang ?? "en",
    }
    axios.post(`https://api.powerofdiaspora.com/${request}`, {data}, {
        headers: headers,
        method: 'post',
    }).then(res=>{
        if(res && res.data) resolve(res.data);
    });
});