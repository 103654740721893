import {Link} from "react-router-dom";
import { useState } from "react";
import { Blog, Contact, Donation, Home, Page, Portfolio } from "./Menus";
import {L, langArr} from "../../helpers/lang/lang";
import {selectAboutMenu} from "../../redux/selectors/aboutMenu";
import {useSelector} from "react-redux";
import Select from "react-select";
import {getLang} from "../../redux/selectors/lang";

const MobileMenu = ({ close }) => {
  const aboutMenuList = useSelector(selectAboutMenu);
  const [activeMenu, setActiveMenu] = useState("");
  const curLang = useSelector(getLang());

  const activeMenuSet = (value) =>
      setActiveMenu(activeMenu === value ? "" : value),
    activeLi = (value) =>
      value === activeMenu ? { display: "block" } : { display: "none" },
    activeClass = (value) => (value === activeMenu ? "open" : "");
  return (
    <div className="mobile-menu">
      <div className="menu-backdrop" />
      <div className="close-btn" onClick={() => close()}>
        <i className="icon far fa-times" />
      </div>
      <nav className="menu-box">
        <div className="nav-logo">
          <Link href="/">
            <a>
              <img src="assets/images/logo-light.png" alt="" title="" />
            </a>
          </Link>
        </div>
        {/*<div><a style={{color: "white"}} href="tel://+37443120055">{L("hotline")} +374 43 12 00 55 </a></div>*/}
        <div className="menu-outer">
          {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
          <div
            className="collapse navbar-collapse show clearfix"
            id="navbarSupportedContent"
          >
            <ul className="navigation">
              <li className="dropdown">
                <Link to="/" onClick={()=>{document.querySelector('.close-btn').click()}}>{L("home")}</Link>
              </li>
              <li className="dropdown">
                <Link to="#">{L("about_us")}</Link>
                <ul style={activeLi("Page")} onClick={()=>{document.querySelector('.close-btn').click()}}>
                  <Page list={aboutMenuList} />
                </ul>
                <div
                  className={`dropdown-btn ${activeClass("Page")}`}
                  onClick={() => activeMenuSet("Page")}
                >
                  <span className="fa fa-angle-right" />
                </div>
              </li>
              <li className="dropdown">
                <Link to="/donation-grid" onClick={()=>{document.querySelector('.close-btn').click()}}>{L("donation_grid")}</Link>
              </li>
              <li className="dropdown">
                <Link to="/blog" onClick={()=>{document.querySelector('.close-btn').click()}}>{L("blog")}</Link>
              </li>
              <Contact />

                <Select
                    value={{label: langArr.find(x => x.id === curLang)?.title}}
                    options={langArr.map(lg=>{
                      return{
                        label: lg.title,
                        value: lg.id
                      }
                    })}
                    onChange={(e)=>{
                      localStorage.setItem("cur-lang", e.value);
                      window.location.reload();
                    }}
                    className={"custom-select"}
                />
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
export default MobileMenu;
