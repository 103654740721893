import {combineReducers, configureStore} from '@reduxjs/toolkit';
import aboutMenu from "../slices/aboutMenu";
import page from "../slices/pageSlice";
import lang from "../slices/lang";
import about_menu from "../slices/pageAbout";
import news from "../slices/pageNews"
import footer_news from "../slices/pageFooterNews";
import newsgallery from "../slices/pageNewsGallery";
import foundation from "../slices/pageFoundation";
import partners from "../slices/pagePartners";
import search_result from "../slices/pageSearch";
import foundationpopup from "../slices/pageFoundationPopUp";
import payment from "../slices/pagePayment";
import search_result_news from "../slices/pageSearchNews";
import send_message from "../slices/pageContact";

const reducer = combineReducers({
    aboutMenu,
    about_menu,
    news,
    footer_news,
    newsgallery,
    foundation,
    partners,
    search_result,
    foundationpopup,
    payment,
    search_result_news,
    send_message,
    page,
    lang
});

const configurator = () => {
    return configureStore({
        devTools: {name: "himnadram"},
        reducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware({
            serializableCheck: false
        })
    })
}

const Store = configurator();

export default Store;
