import {Link, useParams} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import Counter from "../components/Counter";
import PageBanner from "../components/PageBanner";
import PatnerLogoSlider from "../components/PatnerLogoSlider";
import Layouts from "../layouts/Layouts";
import {itemTwoSlider} from "../sliderProps";
import {useDispatch, useSelector} from "react-redux";
import {getLang} from "../redux/selectors/lang";
import {useEffect} from "react";
import {L} from "../helpers/lang/lang";
import {getPage} from "../redux/selectors/pageSelector";
import {setLang} from "../redux/slices/lang";
import {conn} from "../helpers/api";
import {setAboutMenuItems} from "../redux/slices/aboutMenu";
import {setPage} from "../redux/slices/pageSlice";
import {image_base_url, image_base_url_files} from "../helpers";
import {setAbout} from "../redux/slices/pageAbout";
import {getAbout} from "../redux/selectors/pageAboutSelector";
import {setPartners} from "../redux/slices/pagePartners";
import {getPartners} from "../redux/selectors/pagePartnersSelector";
import bg_partners from "../public/assets/images/background/cta.jpg";
import {fiveItemCarousel, testimonialSlider} from "../sliderProps";
import {setFooterNews} from "../redux/slices/pageFooterNews";

const About = () => {
    const params = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLang(localStorage?.getItem("cur-lang") ?? "en"));

        conn("partners", {}).then(res => {
            dispatch(setAboutMenuItems(res.about_menu));
            dispatch(setPartners(res.partners));
        })
    }, [])

    useEffect(() => {
        conn("about", {id: params.id}).then(res => {
            dispatch(setFooterNews(res.footer_news));
            dispatch(setAbout(res));
        })
    }, [params]);

    const lg = useSelector(getLang());
    const aboutPage = useSelector(getAbout());
    const pagePartners = useSelector(getPartners());

    return (
        <Layouts>
            <PageBanner pageName={L("about_us")} image={aboutPage?.about?.image? image_base_url + aboutPage?.about?.image : undefined}/>
            {/*End Breadcrumb Section One*/}
            {/* why choose us section */}
            <section className="why-choose-us style-two">
                <div className="theme_container">
                    <div className="row align-items-center">
                        <div className="col-12 mb-5">
                            <div className="choose-us-block-two style-four">
                                <div className="sub-title mb-20">
                  <span className="title-sep-left">
                    <img src="assets/images/resource/icon-4.png" alt=""/>
                  </span>
                                    {/* {L("why_choose_us")} */}
                                </div>
                                <h2 className="sec-title mb-20">
                                    {
                                        aboutPage?.about?.title
                                    }
                                </h2>

                                {aboutPage?.about?.content?.map(val => {
                                    if (val.type === "title") {
                                        return <h2 className="sec-title mb-20">
                                            {
                                                val.title
                                            }
                                        </h2>
                                    } else if (val.type === "text") {
                                        return <div className="text text-justify">
                                            {val.text.replaceAll("&quot;", '"')}
                                        </div>
                                    } else if (val.type === "video") {
return <div className="col-lg-8 col-md-12 mx-auto">
    <div className="choose-us-block style-two">
        <div className="video_youtube">

                                <iframe src={"https://www.youtube.com/embed/" + val.url}
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen/>

        </div>
    </div>
</div>
                                    } else if (val.type === "image") {
return  <div className="image">
    <img src={image_base_url + val.image} alt=""/>
</div>
                                    } else if (val.type === "file") {
return  <div className="file">
    <a href={"https://docs.google.com/gview?embedded=true&url=" + image_base_url_files + val.image} target={"_blank"}>{val?.["text_" + lg]}</a>
</div>
                                    }
                                })}


                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* what we do section */}
            <section className="what-we-do-section">
                <div
                    className="bg"
                    style={{
                        backgroundImage: "url(assets/images/resource/service-map.png)",
                    }}
                />
                <div className="theme_container">
                    <div className="title-box">
                        <div className="sub-title mb-20">
              <span className="title-sep-left">
                <img src="assets/images/resource/icon-4.png" alt=""/>
              </span>
                            {/* {L("what_we_do")} */}
                        </div>
                        {/* <h2 className="sec-title">
              {L("what_we_do_text")}
            </h2> */}
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="what-we-do-block"
                                style={{
                                    backgroundImage: "url(assets/images/resource/about-5-3.jpg)",
                                }}
                            >
                                <div className="icon-box">
                                </div>
                                <h4>{L("project_done")}</h4>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="what-we-do-block"
                                style={{
                                    backgroundImage: "url(assets/images/resource/about-5-3.jpg)",
                                }}
                            >
                                <div className="icon-box style-two">
                                </div>
                                <h4>{L("expert_volunteer")}</h4>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="what-we-do-block"
                                style={{
                                    backgroundImage: "url(assets/images/resource/about-5-3.jpg)",
                                }}
                            >
                                <div className="icon-box style-three">
                                </div>
                                <h4>{L("global_partners")}</h4>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="what-we-do-block"
                                style={{
                                    backgroundImage: "url(assets/images/resource/about-5-3.jpg)",
                                }}
                            >
                                <div className="icon-box style-four">
                                </div>
                                <h4>{L("raised_now")}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Partner section */}
            <section className="partner">
                <div
                    className="bg"
                    style={{backgroundImage: "url(" + bg_partners + ")"}}
                />
                <div className="theme_container">
                    <Swiper
                        {...fiveItemCarousel}
                        className="five-item-carousel owl-theme owl-carousel"
                    >
                        {

                            Object.values(pagePartners ?? {})?.map(val => {
                                return (
                                    <>
                                        <SwiperSlide className="image" style={{height: "60px", textAlign: "center"}}>
                                            <img src={image_base_url + val.image} style={{height: "100%"}} alt=""/>
                                        </SwiperSlide>
                                    </>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </section>
        </Layouts>
    );
};
export default About;
