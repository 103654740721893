import {Link} from "react-router-dom";
import {L} from "../helpers/lang/lang";
import bg_photo from "../public/assets/images/resource/cover.jpg";

const PageBanner = ({ pageName, pageTitle, image }) => {
  return (
    <section
      className="page-title"
      style={{
        backgroundImage: "url(" + (image ?? bg_photo) + ")",
      }}
    >
      <div className="theme_container">
        <div className="content-box">
          <div className="shape">
            <img src="assets/images/resource/star-icon-2.png" alt="" />
          </div>
          <div className="shape-two">
            <img src="assets/images/resource/page-title-1.png" alt="" />
          </div>
          <div className="shape-three">
            <img src="assets/images/resource/icon-3.png" alt="" />
          </div>
          <div className="content-wrapper">
            <div className="title">
              <h1>{pageTitle ? pageTitle : pageName}</h1>
            </div>
            <ul className="bread-crumb">
              <li>
                <Link to="/">
                  <a>{L("home")}</a>
                </Link>
              </li>
              <li>{pageName}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PageBanner;
