import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const search_result = createSlice({
    name: "search_result",
    initialState: initialProfileState,
    reducers: {
        setSearch: (state, {payload}) => {
           return payload;
        }
    }
});

export const {setSearch} = search_result.actions;

export default search_result.reducer;