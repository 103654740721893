import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const foundationpopup = createSlice({
    name: "foundationpopup",
    initialState: initialProfileState,
    reducers: {
        setFoundationPopUp: (state, {payload}) => {
           return payload;
        }
    }
});

export const {setFoundationPopUp} = foundationpopup.actions;

export default foundationpopup.reducer;