import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const partners = createSlice({
    name: "partners",
    initialState: initialProfileState,
    reducers: {
        setPartners: (state, {payload}) => {
            return payload;
        }
    }
});

export const {setPartners} = partners.actions;

export default partners.reducer;