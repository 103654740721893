import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";

export const foundationAdapter = createEntityAdapter({
    selectId: (foundation) => foundation.id,
});

const initialProfileState = {}

const foundation = createSlice({
    name: "foundation",
    initialState: foundationAdapter.getInitialState([]),
    reducers: {
        setAllFoundation: foundationAdapter.setAll,
        setOneFoundation: foundationAdapter.setOne,
        setMany: foundationAdapter.setMany
    }
});

export const {setAllFoundation, setOneFoundation, setMany} = foundation.actions;

export default foundation.reducer;