import {Link} from "react-router-dom";
import { Fragment } from "react";
import {L} from "../../helpers/lang/lang";

export const Page = (props = []) => {
    const {list} = props;
    return (
        <Fragment>
            {list?.map(val => {
                return (<li key={val.link}>
                    <Link to={"/about/" + val.link}><span>{val.title}</span></Link>
                </li>)
            })}
        </Fragment>
    )
};
export const Contact = () => (
  <li>
    <Link to="/contact" onClick={()=>{document.querySelector('.close-btn').click()}}>{L("contact")}</Link>
  </li>
);
