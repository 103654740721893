import {Link, useParams} from "react-router-dom";
import Counter from "../components/Counter";
import PatnerLogoSlider from "../components/PatnerLogoSlider";
import { Swiper, SwiperSlide } from "swiper/react";
import PageBanner from "../components/PageBanner";
import Layouts from "../layouts/Layouts";
import { fiveItemCarousel } from "../sliderProps";
import DonutChart from "../components/DonutChart";
import {L} from "../helpers/lang/lang";
import {useEffect} from "react";
import {setLang} from "../redux/slices/lang";
import {conn} from "../helpers/api";
import {setAboutMenuItems} from "../redux/slices/aboutMenu";
import {setNewsGallery} from "../redux/slices/pageNewsGallery";
import {getNewsGallery} from "../redux/selectors/pageNewsGallery";
import {useDispatch, useSelector} from "react-redux";
import {image_base_url} from "../helpers";
import bg_partners from "../public/assets/images/background/cta.jpg";
import {setPartners} from "../redux/slices/pagePartners";
import {getPartners} from "../redux/selectors/pagePartnersSelector";
import {setOneFoundation} from "../redux/slices/pageFoundation";
import {selectAllFoundation} from "../redux/selectors/pageFoundationSelector";
import {setMany} from "../redux/slices/pageFoundation";
import {foundationById} from "../redux/selectors/pageFoundationSelector";
import {setFooterNews} from "../redux/slices/pageFooterNews";
import {poggressbar} from "../utils";

const DonationDetails = () => {

  const params = useParams();
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(setLang(localStorage?.getItem("cur-lang") ?? "en"));
  }, [])

  useEffect(()=>{
    conn("foundation", {id: params.id}).then(res=>{
      dispatch(setAboutMenuItems(res.about_menu));
      dispatch(setFooterNews(res.footer_news));
      dispatch(setOneFoundation(res.foundation));
      setTimeout(poggressbar, 200)
    })
  }, [params])

  useEffect(()=>{
    conn("newsgallery", {}).then(res=>{
      dispatch(setNewsGallery(res.newsgallery));
    })

    conn("partners", {}).then(res=>{
      dispatch(setPartners(res.partners));
    })
  }, []);

  const page = useSelector(state=> foundationById(state, params?.id));
  const pageNewsGallery = useSelector(getNewsGallery());
  const pagePartners = useSelector(getPartners());

  let percentValue = page?.goal / 100;
  let percentStatus = (page?.raised / percentValue).toFixed(0);

  return (
    <Layouts>
      <PageBanner pageName={L("donation_list")} />
      <section className="donation-detalis-section">
        <div className="theme_container">
          <div className="row">
                    <div className="col-lg-8">
                      <div className="donation-detalis-block">
                        <h5>
                          {page?.title}
                        </h5>
                        <div className="image">
                          <img
                              src={image_base_url + page?.image}
                              alt=""
                          />
                          {
                            page?.content?.map(val => {
                              if (val.image !== null) {
                                return (
                                    <>
                                      <br />
                                      <br />
                                      <img
                                          src={image_base_url + val.image}
                                          alt=""
                                      />
                                    </>
                                )
                              }
                            })
                          }
                        </div>
                        <div className="video_youtube">
                          {
                            page?.content?.map(val => {
                              if (val.url !== null) {
                                return (
                                    <>
                                      <br />
                                      <iframe src={"https://www.youtube.com/embed/" + val.url}
                                              title="YouTube video player" frameBorder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowFullScreen />
                                    </>
                                )
                              }
                            })
                          }
                        </div>
                        <div className="text">
                          <h4>
                            {
                              page?.content?.map(val => {
                                if (val.title !== null && val.title !== undefined) {
                                  return (
                                      <>
                                        <div className="title_standart">
                                          {val.title}
                                        </div>
                                      </>
                                  )
                                }
                              })
                            }

                            {
                              page?.content?.map(val => {
                                if (val.text !== null && val.text !== undefined) {
                                  return (
                                      <>
                                        <div className={'text'}>
                                          <div className="title_standart">
                                            {val.text}
                                          </div>
                                        </div>
                                      </>
                                  )
                                }
                              })
                            }
                          </h4>
                        </div>
                        {/* counter block start */}
                        <div className="counter-block-three-single style-seven">
                          <h4 className="title">
                            {L("raised")}<span>֏{page?.raised}</span>
                          </h4>
                          <div className="bar">
                            <div className="count-text clr3">{percentStatus}%</div>
                            <div className="bar-inner bg4 count-bar" data-percent={percentStatus + "%"}>
                            </div>
                          </div>
                          <ul>
                            <li>
                              {L("goal")}<span className="crl4">֏{page?.goal}</span>
                            </li>
                            {/* <li>
                              {L("raise")}<span className="crl2">${page?.raise}</span>
                            </li>
                            <li>
                              {L("to_go")}<span className="crl3">${page?.to_go}</span>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>

            <div className="col-lg-4">
              <div className="event-details-block-two">
                <h4>
                  {L("gallery_photo")}
                  <img src="assets/images/resource/icon-4.png" alt="" />
                </h4>
                <div className="inner-box">
                  <div className="wrapper-box">
                    {
                      Object.values(pageNewsGallery ?? {})?.map(val => {
                        return (
                            <div className="image">
                              <img
                                  src={image_base_url + val.image}
                                  alt=""
                              />
                              <div className="overlay-link">
                                <a
                                    href={image_base_url + val.image}
                                    className="lightbox-image"
                                    data-fancybox="gallery"
                                >
                                  <span className="fab fa-instagram" />
                                </a>
                              </div>
                            </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Partner section */}
      <section className="partner">
        <div
            className="bg"
            style={{ backgroundImage: "url(" + bg_partners + ")" }}
        />
        <div className="theme_container">
          <Swiper
              {...fiveItemCarousel}
              className="five-item-carousel owl-theme owl-carousel"
          >
            {

              Object.values(pagePartners ?? {})?.map(val => {
                return (
                    <>
                      <SwiperSlide className="image" style={{height: "60px", textAlign: "center"}}>
                        <img src={image_base_url + val.image} style={{height: "100%"}} alt=""/>
                      </SwiperSlide>
                    </>
                )
              })
            }
          </Swiper>
        </div>
      </section>
    </Layouts>
  );
};
export default DonationDetails;
