import {createSlice} from "@reduxjs/toolkit";

const initialProfileState = {}

const send_message = createSlice({
    name: "send_message",
    initialState: initialProfileState,
    reducers: {
        setMessage: (state, {payload}) => {
           return payload;
        }
    }
});

export const {setMessage} = send_message.actions;

export default send_message.reducer;