import {Link} from "react-router-dom";
import Counter from "../components/Counter";
import PageBanner from "../components/PageBanner";
import PatnerLogoSlider from "../components/PatnerLogoSlider";
import Layouts from "../layouts/Layouts";
import DonutChart from "../components/DonutChart";
import {L} from "../helpers/lang/lang";
import {useEffect, useState} from "react";
import {setLang} from "../redux/slices/lang";
import {conn} from "../helpers/api";
import {setAboutMenuItems} from "../redux/slices/aboutMenu";
import {useDispatch, useSelector} from "react-redux";
import {image_base_url} from "../helpers";
import {setAllFoundation, setMany} from "../redux/slices/pageFoundation";
import {selectAllFoundation} from "../redux/selectors/pageFoundationSelector";
import {setFooterNews} from "../redux/slices/pageFooterNews";

const DonationGrid = () => {

  const dispatch = useDispatch();
  const [from, setFrom] = useState(0);
  const [count_all, setCount_all] = useState(0);

  useEffect(()=>{
    dispatch(setLang(localStorage?.getItem("cur-lang") ?? "en"));
  }, [])

  useEffect(()=>{
    conn("foundation", {from: from}).then(res=>{
      dispatch(setAboutMenuItems(res.about_menu));
      dispatch(setFooterNews(res.footer_news));
      setCount_all(res.count_all);
      dispatch(setMany(res.foundation));
    })
  }, [from]);

  const page = useSelector(selectAllFoundation);

  return (
    <Layouts>
      <PageBanner pageName={L("donation_grid")} />
      <section className="donation-section">
        <div className="theme_container">
          <div className="title-box text-center">
            <div className="sub-title mb-20">
              <span className="title-sep-left">
                <img src="assets/images/resource/icon-4.png" alt="" />
              </span>
              {/* {L("popular_causes")} */}
              <span className="title-sep-right">
                <img src="assets/images/resource/icon-4.png" alt="" />
              </span>
            </div>
            <h2 className="sec-title">
              {L("how_help_foundation")}
            </h2>
          </div>
          <div className="row">
            {
              Object.values(page ?? {})?.map(val => {
                let percentValue = val.goal / 100;
                let percentStatus = (val.raised / percentValue).toFixed(0);
                return (
                    <div className="col-lg-4">
                      <div className="causes-block style-two">
                        <div className="image">
                          <img src={image_base_url + val.image} alt="" />
                          <div className="progress-wrap">
                            <div className="progress-box">
                              <div className="inner-box">
                                <div className="graph-outer">
                                  <DonutChart value={percentStatus} color={"#f74f22"} />

                                  <div className="inner-text count-box">
                                    <Counter end={percentStatus} />
                                    <span className="count-Parsent">%</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="content">
                              <h4>{val.percent_title}</h4>
                              <div className="text">
                                {L("raised")}<span className="crl4"> ֏{val.raised}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="lower-content">
                          <h4>
                            {val.title}
                          </h4>
                          <ul>
                            <li>
                              {L("goal")}<span>֏{val.goal}</span>
                            </li>
                            {/* <li>
                              {L("raise")}<span className="crl2">${val.raise}</span>
                            </li>
                            <li>
                              {L("to_go")}<span className="crl3">${val.to_go}</span>
                            </li> */}
                          </ul>
                          <Link to={"/donation-details/" + val.id}>
                            <a className="primary_btn style-two">
                              {L("read_more")}
                              <i className="far fa-heart" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    </div>
                )
              })
            }
          </div>
        </div>
        <div className={'post-cat-three_new_button'} onClick={() => {
          setFrom(prev=> prev + 10);
        }}>{L("show_more")}</div>
      </section>
    </Layouts>
  );
};
export default DonationGrid;
